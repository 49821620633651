// extracted by mini-css-extract-plugin
export var articleDiv = "blog-post-module--articleDiv--mwcWZ";
export var articleReleasedDate = "blog-post-module--articleReleasedDate--YtROm";
export var articleTitle = "blog-post-module--articleTitle--9u+Zc";
export var mainInfoSocialHref = "blog-post-module--mainInfoSocialHref--NQAzU";
export var mainInfoSocialImg = "blog-post-module--mainInfoSocialImg--Yy4vl";
export var navigatePosts = "blog-post-module--navigatePosts--ic7qj";
export var navigatePostsItem = "blog-post-module--navigatePostsItem--1RBXr";
export var popup = "blog-post-module--popup--0gAhy";
export var sharableDiv = "blog-post-module--sharableDiv--+Ip-c";
export var tag = "blog-post-module--tag--oJ-BN";
export var tagsDiv = "blog-post-module--tagsDiv--4iuBm";