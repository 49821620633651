import React from "react"
import _ from "lodash"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import * as styles from './blog-post.module.css'
import * as tagStyles from '../pages/tags.module.css'
import TwitterImg from "../utils/twitter.png"
import ShareImg from "../utils/share.png"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const tags = post.frontmatter.tags
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const sharableLink = "https://twitter.com/share?url=" + location.href// location.pathname

  console.log(tags);

  let tagDiv;
  if (tags) {
    tagDiv = (
      tags.map(tag => {
        if (tag === "travel") {
          return (
            <Link
              key={tag}
              // style={{ textDecoration: "none" }}
              className={tagStyles.tag}
              to={`/${_.kebabCase(tag)}`}
            >
              {tag}
            </Link>
          )
        }
        return (
          <Link
            key={tag}
            // style={{ textDecoration: "none" }}
            className={tagStyles.tag}
            to={`/tags/${_.kebabCase(tag)}`}
          >
            {tag}
          </Link>
        )
      })
    )
  }

  let navigationNextPrePost;
  let sharableDiv;
  let nextIsAbout = next && !next.fields.slug.includes("/about");
  let preIsAbout = previous && !previous.fields.slug.includes("/about");

  if (!location.pathname.includes("/about")) {
    navigationNextPrePost =
     <nav>
      <ul className={styles.navigatePosts}>
        <li className={styles.navigatePostsItem} >
          {preIsAbout && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li className={styles.navigatePostsItem} >
          {nextIsAbout && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>
    </nav>

    sharableDiv = 
    <div className={styles.sharableDiv}>
      <a className={styles.mainInfoSocialHref} href={sharableLink} target="_blank">
        <img src={TwitterImg} alt="Twitter" className={styles.mainInfoSocialImg} />
      </a>

      <a className={styles.mainInfoSocialHref} target="_blank" onClick={copyToClipboard}>
        <img src={ShareImg} alt="Copy link" className={styles.mainInfoSocialImg} />
      </a>
      <div id={styles.popup}>Copied</div>
    </div>
  }

  function copyToClipboard() {
    var textarea = document.createElement("textarea");
    textarea.textContent = location.href;
    textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    var popup = document.getElementById(styles.popup);
    if (popup.style.display === "none") {
      popup.style.display = "block";

      setTimeout(function (e) {
        popup.style.display = "none";
      }, 500);
    } else {
      popup.style.display = "none";
    }
  }

  return (
    <Layout location={location} title={siteTitle} metaData={data.site.siteMetadata}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <article className={styles.articleDiv}>

        {sharableDiv}

        <header>
          <p className={styles.articleTitle}>{post.frontmatter.title}</p>
        </header>

        <section dangerouslySetInnerHTML={{ __html: post.html }} />

        <hr style={{
            marginBottom: rhythm(1),
          }}
        />
      </article>

      <div className={tagStyles.tagsDiv}>{tagDiv}</div>

      {navigationNextPrePost}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`
